import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import style from "./FormContacto.module.scss";
import config from '../../config/config';
import { FB_EVENTS, sendPixelEvent } from '../../services/facebook';
export const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function FormContacto({ message, decorated, withPhoto, referencia, is_servicios }) {
    const [nombre, setNombre] = useState("");
    const [validNombre, setValidNombre] = useState(false);
    const [telefono, setTelefono] = useState("");
    const [validTelefono, setValidTelefono] = useState(false);
    const [correo, setCorreo] = useState("");
    const [validCorreo, setValidCorreo] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const router = useRouter();

    const setNombreValue = (event) => {
        setNombre(event?.target.value);
    }
    const setTelefonoValue = (event) => {
        setTelefono(event?.target.value);
    }
    const setCorreoValue = (event) => {
        setCorreo(event?.target.value);
    }
    const setMensajeValue = (event) => {
        setMensaje(event?.target.value);
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (validNombre == false || validTelefono == false || validTelefono == false) {
            setShowErrors(true);
            return;
        }

        const data = Object.fromEntries(new FormData(event.target).entries());

        data.referencia = referencia;

        await fetch(config().apiUrl + '/formulario-contactos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data })
        });

        await sendPixelEvent(FB_EVENTS.contact, nombre, telefono, correo);

        router.push("/thankyou")
    }

    const required = (value) => {
        if (value == null) {
            return false;
        }

        if (value.trim() == "") {
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (showErrors === true) {
            setShowErrors(false);
        }
        setValidNombre(required(nombre));
    }, [nombre]);

    useEffect(() => {
        if (showErrors === true) {
            setShowErrors(false);
        }
        setValidTelefono(required(telefono));
    }, [telefono]);

    useEffect(() => {
        if (showErrors === true) {
            setShowErrors(false);
        }
        setValidCorreo(required(correo) && emailRegex.test(correo));
    }, [correo]);

    return (
        <div className={`container py-2 py-md-5 ${style['d-padding']}`} style={{ marginTop: '-42px' }}>
            <div className="row">
                <div className={`col-md-6 ${decorated == true && style.decoration__container}`}>
                    {
                        withPhoto == false && (
                            <>
                                <div className={style.square__one}></div>
                                <div className={style.square__two}></div>
                                <div className={style.intersection}></div>
                            </>
                        )
                    }
                    <div className={style.title__container}>
                        <h1 className={`${style['header-contacto']} fw-bold arial-black text-black`} style={{ color: 'black!important' }}>CONTACT
                        </h1>
                        <span className={`${style['header-contacto']} fw-bold text-orange arial-black`}>US</span>
                    </div>
                    {
                        message != null
                            ? <p className={style['ng-desktop']}>
                                <span className="fw-bold">Únete a nosotros y juntos dominaremos la Galaxia... de los mercados.</span> <br />
                                Siempre tenemos nuestra bandeja abierta para nuevos proyectos (y love letters y fotos de corgis).
                            </p>
                            : <div className={style.message__container}>
                                {is_servicios == false ?
                                    <div>
                                        <p>
                                            ¿Quieres encontrar tu marca pero no sabes por dónde empezar?
                                        </p>
                                        <p className="fw-bold">
                                            ¡Contáctanos y busquémosla juntos!
                                        </p>
                                    </div>
                                    :
                                    <div>
                                        <p>
                                            A ti te emociona tu proyecto. <br /> A nosotros, trabajar en tu proyecto como si fuera nuestro.
                                        </p>
                                        <p className="fw-bold">
                                            Mándanos un mensaje con el servicio que necesites.
                                        </p>
                                    </div>
                                }
                            </div>
                    }
                    {
                        withPhoto && (
                            <div className={style.photo__container}>
                                <img src="/img/lomito.png" alt="perrito" className={style.photo} />
                            </div>
                        )
                    }
                </div>
                <div className="col-md-6">
                    <form onSubmit={submitForm}>
                        <div className={style.input__group}>
                            <input
                                value={nombre}
                                onChange={setNombreValue}
                                type="text"
                                name="nombre"
                                placeholder="Nombre *"
                                className={style.form__input}
                            />
                            <p className={`${style['input-error']} ${showErrors && !validNombre ? 'visible' : 'invisible'}`}>
                                Este campo es requerido
                            </p>
                        </div>
                        <div className={style.input__group}>
                            <input
                                value={telefono}
                                onChange={setTelefonoValue}
                                type="tel"
                                name="telefono"
                                placeholder="Teléfono *"
                                className={style.form__input}
                            />
                            <p className={`${style['input-error']} ${showErrors && !validTelefono ? 'visible' : 'invisible'}`}>
                                Este campo es requerido
                            </p>
                        </div>
                        <div className={style.input__group}>
                            <input
                                value={correo}
                                onChange={setCorreoValue}
                                type="email"
                                name="correo"
                                placeholder="Correo *"
                                className={style.form__input}
                            />
                            <p className={`${style['input-error']} ${showErrors && !validCorreo ? 'visible' : 'invisible'}`}>
                                Ingrese un correo válido
                            </p>
                        </div>
                        <input
                            value={mensaje}
                            onChange={setMensajeValue}
                            type="text"
                            name="mensaje"
                            placeholder="Mensaje"
                            className={style.form__input} /><br />
                        <button
                            type="submit"
                            className="d-block black-to-orange-btn ms-auto my-5">
                            ENVIAR
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}